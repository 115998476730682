import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { MediaPath } from '../media-path';
import { Press } from '../press';

export interface IBackendPressLanguage {
  id?: number;
  title?: string;
  language?: string;
}

export interface IPressLanguage {
  id?: number;
  title?: string;
  language?: string;
}

export class PressLanguage implements IPressLanguage {
  static readonly clean = Object.freeze(new PressLanguage());
  id = 0;
  title = '';
  language = '';

  parse(pressLanguage: IBackendPressLanguage) {
    this.id = Number(pressLanguage.id || PressLanguage.clean.id);
    this.title = String(pressLanguage.title || PressLanguage.clean.title);
    this.language = String(pressLanguage.language || PressLanguage.clean.language);
    return this;
  }

  set(pressLanguage: IPressLanguage) {
    Object.entries(this).forEach(([key]) => {
      this[key] = pressLanguage[key] || this[key];
    });

    return this;
  }

  clone() {
    return new PressLanguage().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = PressLanguage.clean[key];
    });

    return this;
  }
}

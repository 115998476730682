import { map } from 'rxjs/operators';
import * as moment from 'moment';

export interface IBackendMediaPath {
  id?: number;

  path?: string;
}

export interface IMediaPath {
  id?: number;

  path?: string;
}

export class MediaPath implements IMediaPath {
  static readonly clean = Object.freeze(new MediaPath());
  id = 0;

  path = '';
  delete = 0;

  createdAt: Date;
  updatedAt: Date;


  parse(mediaPath: IBackendMediaPath) {
    this.id = Number(mediaPath.id || MediaPath.clean.id);
    this.path = String(mediaPath.path || MediaPath.clean.path);

    return this;
  }

  set(mediaPath: IMediaPath) {
    Object.entries(this).forEach(([key]) => {
      this[key] = mediaPath[key] || this[key];
    });

    return this;
  }

  clone() {
    return new MediaPath().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = MediaPath.clean[key];
    });

    return this;
  }
}

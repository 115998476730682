
export interface IBackendInitiativeLanguage {
  id?: number;
  work_area?: number;
  body?: string;
  title?: string;
  language?: string;
  short_description?: string;

}

export interface IInitiativeLanguage {
  id?: number;
  
  body?: string;
  title?: string;
  work_area?: number;
  language?: string;
  shortDescription?: string;


}

export class InitiativeLanguage implements IInitiativeLanguage {
  static readonly clean = Object.freeze(new InitiativeLanguage());
  id = 0;

  title = '';
  body = '';
  language = '';
  work_area= 0;

  shortDescription = '';



  parse(initiativeLanguage: IBackendInitiativeLanguage) {
    this.id = Number(initiativeLanguage.id || InitiativeLanguage.clean.id);

    this.title = String(initiativeLanguage.title || InitiativeLanguage.clean.title);
    this.body = String(initiativeLanguage.body || InitiativeLanguage.clean.body);
    this.work_area = Number(initiativeLanguage.work_area || InitiativeLanguage.clean.work_area);
    
    this.language = String(initiativeLanguage.language || InitiativeLanguage.clean.language);
    this.shortDescription = String(initiativeLanguage.short_description || InitiativeLanguage.clean.shortDescription);


    return this;
  }

  set(initiativeLanguage: IInitiativeLanguage) {
    Object.entries(this).forEach(([key]) => {
      this[key] = initiativeLanguage[key] || this[key];
    });

    return this;
  }

  clone() {
    return new InitiativeLanguage().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = InitiativeLanguage.clean[key];
    });

    return this;
  }
}

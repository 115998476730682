import * as moment from 'moment';
import { DocumentLanguage } from '../document-language';
import { MediaPath } from '../media-path';

export interface IBackendDocument {
  id?: number;
  order?: number;
  type?: string;
  cover_path?: string;
  attachment_path?: string;
  default_lang?: string;
  languages?: DocumentLanguage[];
  created_at?: string;
  title?: string;
  description?: string;
  updated_at?: string;
}

export interface IDocument {
  id?: number;
  order?: number;
  description?: string;
  title?: string;
  default_lang?: string;
  type?: string;
  locationName?: string;
  coverPath?: string;
  attachmentPath?: string;
  languages?: DocumentLanguage[];
  createdAt?: Date | string;
  updatedAt?: Date | string;

}

export class Document implements IDocument {
  static readonly clean = Object.freeze(new Document());
  id = 0;
  order = 0;
  delete = false;
  selected = false;
  coverPath = '';
  default_lang = '';
  type = '';
  title = '';
  description = '';
  languages: DocumentLanguage[] = [];
  createdAt: Date;
  updatedAt: Date;
  attachmentPath = '';


  parse(document: IBackendDocument) {
    this.id = Number(document.id || Document.clean.id);
    this.order = Number(document.order || Document.clean.order);
    
    this.coverPath = String(document.cover_path || Document.clean.coverPath);
    this.default_lang = String(document.default_lang || Document.clean.default_lang);
    this.attachmentPath = String(document.attachment_path || Document.clean.attachmentPath);
    // tslint:disable-next-line: max-line-length
    this.languages = (document.languages || Document.clean.languages).map((language: DocumentLanguage) => new DocumentLanguage().parse(language));

    this.type = String(document.type || Document.clean.type);
    this.title = String(document.title || Document.clean.title);
    this.description = String(document.description || Document.clean.description);

    const createdAt = document.created_at || Document.clean.createdAt;
    this.createdAt = createdAt ? moment.utc(createdAt).toDate() : null;
    const updatedAt = document.updated_at || Document.clean.updatedAt;
    this.updatedAt = updatedAt ? moment.utc(updatedAt).toDate() : null;

    return this;
  }

  set(document: IDocument) {
    Object.entries(this).forEach(([key]) => {
      this[key] = document[key] || this[key];
    });

    return this;
  }

  clone() {
    return new Document().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = Document.clean[key];
    });

    return this;
  }
}

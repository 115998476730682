import * as moment from 'moment';
import { PressLanguage } from '../press-language';

export interface IBackendPress {
  id?: number;

  link?: string;
  title?: string;
  logo_path?: string;
  link_lang?: string;
  medium?: string;
  date?: string;
  languages?: PressLanguage[];

}

export interface IPress {
  id?: number;
  title?: string;
  link_lang?: string;

  link?: string;
  logo_path?: string;
  medium?: string;
  date?: string;
  languages?: PressLanguage[];
}

export class Press implements IPress {
  static readonly clean = Object.freeze(new Press());

  id = 0;
  link = "";
  title = "";
  logo_path = "";
  medium = "";
  link_lang = "";
  date = "";
  languages: PressLanguage[] = [];

  parse(press: IBackendPress) {
    this.id = Number(press.id || Press.clean.id);

    this.link = String(press.link || Press.clean.link);
    this.title = String(press.title || Press.clean.title);
    this.logo_path = String(press.logo_path || Press.clean.logo_path);
    this.link_lang = String(press.link_lang || Press.clean.link_lang);
    this.medium = String(press.medium || Press.clean.medium);
    this.date = String(press.date || Press.clean.date);
    this.languages = (press.languages || Press.clean.languages).map((language: PressLanguage) => new PressLanguage().parse(language));    

    return this;
  }

  set(press: IPress) {
    Object.entries(this).forEach(([key]) => {
      this[key] = press[key] || this[key];
    });

    return this;
  }

  clone() {
    return new Press().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = Press.clean[key];
    });

    return this;
  }
}

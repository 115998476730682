import * as moment from 'moment';
import { MediaPath } from '../media-path';
import { Initiative } from '../initiative';
import { Language } from '../language';
import { App } from '../app';

export interface IBackendLocation {
  id?: number;

  label?: string;
  x?: number;
  y?: number;
  population?: string;
  description?: string;
  country_name?: string;
  below_pov_line?: string;
  without_bank_acc?: string;
  year_started?: string;
  people_reached?: string;
  cover_path?: string;
  total_initiatives?: number;
  total_apps?: number;
  flag_path?: string;
  apps?: App[];
  partners?: MediaPath[];
  slider?: MediaPath[];
  initiatives?: Initiative[];
  languages?: Language[];
  continent?: string;



  created_at?: string;
  updated_at?: string;
}

export interface ILocation {
  id?: number;

  label?: string;
  x?: number;
  y?: number;
  population?: string;
  description?: string;
  countryName?: string;
  belowPovLine?: string;
  withoutBankAcc?: string;
  peopleReached?: string;
  coverPath?: string;
  totalInitiatives?: number;
  totalApps?: number;
  yearStarted?: string;
  flagPath?: string;
  apps?: App[];
  partners ?: MediaPath[];
  slider?: MediaPath[];
  initiatives?: Initiative[];
  languages?: Language[];
  continent?: string;



  createdAt?: Date | string;
  updatedAt?: Date | string;
}

export class Location implements ILocation {
  static readonly clean = Object.freeze(new Location());
  id = 0;

  label = '';
  x = 0;
  y = 0;
  population = '';
  description = '';
  countryName = '';
  belowPovLine = '';
  withoutBankAcc = '';
  yearStarted = '';
  peopleReached = '';
  coverPath = '';
  totalInitiatives = 0;
  totalApps = 0;
  initiatives: Initiative[] = [];
  apps: App[] = [];
  partners: MediaPath[] = []; 
  slider: MediaPath[] = [];
  languages: Language[] = [];
  continent = '';
  flagPath = '';

  createdAt: Date;
  updatedAt: Date;


  parse(location: IBackendLocation) {
    this.id = Number(location.id || Location.clean.id);

    this.label = String(location.label || Location.clean.label);
    this.x = Number(location.x || Location.clean.x);
    this.y = Number(location.y || Location.clean.y);
    this.population = String(location.population || Location.clean.population);
    this.description = String(location.description || Location.clean.description);
    this.countryName = String(location.country_name || Location.clean.countryName);
    this.belowPovLine = String(location.below_pov_line || Location.clean.belowPovLine);
    this.withoutBankAcc = String(location.without_bank_acc || Location.clean.withoutBankAcc);
    this.yearStarted = String(location.year_started || Location.clean.yearStarted);
    this.peopleReached = String(location.people_reached || Location.clean.peopleReached);
    this.coverPath = String(location.cover_path || Location.clean.coverPath);
    this.totalInitiatives = Number(location.total_initiatives || Location.clean.totalInitiatives);
    this.totalApps = Number(location.total_apps || Location.clean.totalApps);
    this.continent = String(location.continent || Location.clean.continent);
    this.flagPath = String(location.flag_path || Location.clean.flagPath);
    this.initiatives = (location.initiatives || Location.clean.initiatives).map(initiative => new Initiative().parse(initiative));
    this.apps = (location.apps || Location.clean.apps).map((app: App) => new App().parse(app));
    this.partners = (location.partners || Location.clean.partners).map((partner: MediaPath) => new MediaPath().parse(partner));
    this.slider = (location.slider || Location.clean.slider).map((item: MediaPath) => new MediaPath().parse(item));
    this.languages = (location.languages || Location.clean.languages).map((language: Language) => new Language().parse(language));

    const createdAt = location.created_at || Location.clean.createdAt;
    this.createdAt = createdAt ? moment.utc(createdAt).toDate() : null;
    const updatedAt = location.updated_at || Location.clean.updatedAt;
    this.updatedAt = updatedAt ? moment.utc(updatedAt).toDate() : null;

    return this;
  }

  set(location: ILocation) {
    Object.entries(this).forEach(([key]) => {
      this[key] = location[key] || this[key];
    });

    return this;
  }

  clone() {
    return new Location().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = Location.clean[key];
    });

    return this;
  }
}

import * as moment from 'moment';

export interface IBackendUser {
  id?: number;

  privilege?: string;
  email?: string;
  full_name?: string;
  phone_number?: string;
  password?: string;

  created_at?: string;
  updated_at?: string;
}

export interface IUser {
  id?: number;

  privilege?: string;
  email?: string;
  fullName?: string;
  phoneNumber?: string;
  password?: string;

  createdAt?: Date | string;
  updatedAt?: Date | string;
}

export class User implements IUser {
  static readonly clean = Object.freeze(new User());
  id = 0;

  privilege = '';
  email = '';
  fullName = '';
  phoneNumber = '';
  password = '';

  createdAt: Date;
  updatedAt: Date;


  parse(user: IBackendUser) {
    this.id = Number(user.id || User.clean.id);

    this.privilege = String(user.privilege || User.clean.privilege);
    this.email = String(user.email || User.clean.email);
    this.fullName = String(user.full_name || User.clean.fullName);
    this.phoneNumber = String(user.phone_number || User.clean.phoneNumber);
    this.password = String(user.password || User.clean.password);
    const createdAt = user.created_at || User.clean.createdAt;
    this.createdAt = createdAt ? moment.utc(createdAt).toDate() : null;
    const updatedAt = user.updated_at || User.clean.updatedAt;
    this.updatedAt = updatedAt ? moment.utc(updatedAt).toDate() : null;

    return this;
  }

  set(user: IUser) {
    Object.entries(this).forEach(([key]) => {
      this[key] = user[key] || this[key];
    });

    return this;
  }

  clone() {
    return new User().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = User.clean[key];
    });

    return this;
  }
}

export interface IBackendJobLanguage {
  id?: number;
  title?: string;
  language?: string;
  description?: string;
  attachment_path?: string;
}

export interface IJobLanguage {
  id?: number;
  title?: string;
  language?: string;
  description?: string;
  attachment_path?: string;
}

export class JobLanguage implements IJobLanguage {
  static readonly clean = Object.freeze(new JobLanguage());
  id = 0;
  title = '';
  language = '';
  description = '';
  attachmentPath = ''; 

  parse(jobLanguage: IBackendJobLanguage) {
    this.id = Number(jobLanguage.id || JobLanguage.clean.id);
    this.attachmentPath = String(jobLanguage.attachment_path || JobLanguage.clean.attachmentPath);
    this.title = String(jobLanguage.title || JobLanguage.clean.title);
    this.language = String(jobLanguage.language || JobLanguage.clean.language);
    this.description = String(jobLanguage.description || JobLanguage.clean.description);


    return this;
  }

  set(jobLanguage: IJobLanguage) {
    Object.entries(this).forEach(([key]) => {
      this[key] = jobLanguage[key] || this[key];
    });

    return this;
  }

  clone() {
    return new JobLanguage().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = JobLanguage.clean[key];
    });

    return this;
  }
}

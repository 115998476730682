import { MemberLanguage } from './../member-language/member-language';
import * as moment from 'moment';
import { MediaPath } from '../media-path';

export interface IBackendMember {
  id?: number;
  full_name?: string;
  email?: string;
  order?: number;
  country?: string;
  category?: string;

  biography?: string;
  icon_path?: string;
  languages?: MemberLanguage[];
}

export interface IMember {
  id?: number;
  email?: string;
  full_name?: string;
  order?: number;
  category?: string;

  country?: string;
  biography?: string;
  icon_path?: string;
  languages?: MemberLanguage[];
}

export class Member implements IMember {
  static readonly clean = Object.freeze(new Member());
  id = 0;

  full_name = '';
  email = '';
  country = '';
  biography = '';
  category = '';
  order = 0;
  icon_path = '';
  languages: MemberLanguage[] = [];


  parse(member: IBackendMember) {
    this.id = Number(member.id  || Member.clean.id);
    this.full_name = String(member.full_name || Member.clean.full_name);
    this.email = String(member.email || Member.clean.email);
    this.order = Number(member.order || Member.clean.order);
    this.country = String(member.country || Member.clean.country);
    this.category = String(member.category || Member.clean.category);

    this.biography = String(member.biography || Member.clean.biography);
    this.icon_path = String(member.icon_path || Member.clean.icon_path);
    this.languages = (member.languages || Member.clean.languages).map((language: MemberLanguage) => new MemberLanguage().parse(language));
    return this;
  }

  set(member: IMember) {
    Object.entries(this).forEach(([key]) => {
      this[key] = member[key] || this[key];
    });

    return this;
  }

  clone() {
    return new Member().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = Member.clean[key];
    });

    return this;
  }
}

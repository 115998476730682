import * as moment from 'moment';
import { WorkAreaLanguage } from '../work-area-language';

export interface IBackendWorkArea {
  id?: number;

  location_id?: number;
  country_name?: string;
  title?: string;
  body?: string;
  cover_path?: string;
  short_description?: string;
  languages?: WorkAreaLanguage[];
  classification?: string;
  poblacion?:string;
  created_at?: string;
  updated_at?: string;
}

export interface IWorkArea {
  id?: number;

  locationId?: number;
  locationName?: string;
  title?: string;
  body?: string;
  coverPath?: string;
  classification?: string;
  description?: string;
  poblacion?:string;

  languages?: WorkAreaLanguage[];

  createdAt?: Date | string;
  updatedAt?: Date | string;
}

export class WorkArea implements IWorkArea {
  static readonly clean = Object.freeze(new WorkArea());
  id = 0;

  locationId = 0;
  locationName = '';
  delete = false;
  selected = false;
  title = '';
  body = '';
  coverPath = '';
  description = '';
  languages: WorkAreaLanguage[] = [];
  classification = '';
  poblacion= '';

  createdAt: Date;
  updatedAt: Date;


  parse(workarea: IBackendWorkArea) {
    this.id = Number(workarea.id || WorkArea.clean.id);

    this.locationId = Number(workarea.location_id || WorkArea.clean.locationId);
    this.locationName = String(workarea.country_name || WorkArea.clean.locationName);
    this.title = String(workarea.title || WorkArea.clean.title);
    this.body = String(workarea.body || WorkArea.clean.body);
    this.coverPath = String(workarea.cover_path || WorkArea.clean.coverPath);
    this.classification = String(workarea.classification || WorkArea.clean.classification);
    this.poblacion = String(workarea.poblacion || WorkArea.clean.poblacion);


    this.description = String(workarea.short_description || WorkArea.clean.description);
    this.languages = (workarea.languages || WorkArea.clean.languages)
    .map((language: WorkAreaLanguage) => new WorkAreaLanguage().parse(language));


    const createdAt = workarea.created_at || WorkArea.clean.createdAt;
    this.createdAt = createdAt ? moment.utc(createdAt).toDate() : null;
    const updatedAt = workarea.updated_at || WorkArea.clean.updatedAt;
    this.updatedAt = updatedAt ? moment.utc(updatedAt).toDate() : null;

    return this;
  }

  set(workarea: IWorkArea) {
    Object.entries(this).forEach(([key]) => {
      this[key] = workarea[key] || this[key];
    });

    return this;
  }

  clone() {
    return new WorkArea().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = WorkArea.clean[key];
    });

    return this;
  }
}

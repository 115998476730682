import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { MediaPath } from '../media-path';
import { WorkArea } from '../work-area';

export interface IBackendWorkAreaLanguage {
  id?: number;

  body?: string;
  title?: string;
  language?: string;
  short_description?: string;

}

export interface IWorkAreaLanguage {
  id?: number;

  body?: string;
  title?: string;
  language?: string;
  shortDescription?: string;


}

export class WorkAreaLanguage implements IWorkAreaLanguage {
  static readonly clean = Object.freeze(new WorkAreaLanguage());
  id = 0;

  title = '';
  body = '';
  language = '';
  shortDescription = '';



  parse(workareaLanguage: IBackendWorkAreaLanguage) {
    this.id = Number(workareaLanguage.id || WorkAreaLanguage.clean.id);

    this.title = String(workareaLanguage.title || WorkAreaLanguage.clean.title);
    this.body = String(workareaLanguage.body || WorkAreaLanguage.clean.body);
    this.language = String(workareaLanguage.language || WorkAreaLanguage.clean.language);
    this.shortDescription = String(workareaLanguage.short_description || WorkAreaLanguage.clean.shortDescription);


    return this;
  }

  set(workareaLanguage: IWorkAreaLanguage) {
    Object.entries(this).forEach(([key]) => {
      this[key] = workareaLanguage[key] || this[key];
    });

    return this;
  }

  clone() {
    return new WorkAreaLanguage().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = WorkAreaLanguage.clean[key];
    });

    return this;
  }
}


import * as moment from 'moment';
import { MediaPath } from '../media-path';

export interface IBackendArchive {
  file_path: any;
  id?: number;
  title?: string;
  created_at: string;
}

export interface IArchive {
  id?: number;
  title?: string;
  filePath: string;
  created_at: string;
}

export class Archive implements IArchive {
  static readonly clean = Object.freeze(new Archive());
  id = 0;
  title = '';
  filePath = '';
  created_at: string;
  file: Blob | File;
 

  parse(archive: IBackendArchive) {
    this.id = Number( archive.id  || Archive.clean.id);
    this.title = String(archive.title || Archive.clean.title);
    this.filePath = String(archive.file_path || Archive.clean.filePath);
    this.created_at = String(archive.created_at || Archive.clean.created_at);
    return this;
  }

  set(archive: IArchive) {
    Object.entries(this).forEach(([key]) => {
      this[key] = archive[key] || this[key];
    });

    return this;
  }

  clone() {
    return new Archive().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = Archive.clean[key];
    });

    return this;
  }
}

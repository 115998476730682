import * as moment from 'moment';

export interface IBackendLibrary {
  id?: number;

  title?: string;
  language?: string;
  description?: string;
  type?: string;

  publish_date?: string;
  created_at?: string;
  updated_at?: string;
}

export interface ILibrary {
  id?: number;

  title?: string;
  language?: string;
  description?: string;
  type?: string;

  publishDate?: Date | string;
  createdAt?: Date | string;
  updatedAt?: Date | string;
}

export class Library implements ILibrary {
  static readonly clean = Object.freeze(new Library());
  id = 0;

  title = '';
  language = '';
  description = '';
  type = '';

  publishDate: Date;
  createdAt: Date;
  updatedAt: Date;


  parse(library: IBackendLibrary) {
    this.id = Number(library.id || Library.clean.id);

    this.title = String(library.title || Library.clean.title);
    this.language = String(library.language || Library.clean.language);
    this.description = String(library.description || Library.clean.description);
    this.type = String(library.type || Library.clean.type);

    const publishDate = library.publish_date || Library.clean.publishDate;
    this.publishDate = publishDate ? moment.utc(publishDate).toDate() : null;
    const createdAt = library.created_at || Library.clean.createdAt;
    this.createdAt = createdAt ? moment.utc(createdAt).toDate() : null;
    const updatedAt = library.updated_at || Library.clean.updatedAt;
    this.updatedAt = updatedAt ? moment.utc(updatedAt).toDate() : null;

    return this;
  }

  set(library: ILibrary) {
    Object.entries(this).forEach(([key]) => {
      this[key] = library[key] || this[key];
    });

    return this;
  }

  clone() {
    return new Library().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = Library.clean[key];
    });

    return this;
  }
}

import { AppLanguage } from './../app-language/app-language';
import * as moment from 'moment';
import { MediaPath } from '../media-path';

export interface IBackendApp {
  id?: number;

  label?: string;
  description?: string;
  short_description?: string;
  order?: number;
  icon_path?: string;
  cover_path?: string;
  languages?: AppLanguage[];
  partners?: MediaPath[];
  created_at?: string;
  updated_at?: string;
  video_path?: string;
  slider?: MediaPath[];

}

export interface IApp {
  id?: number;
  label?: string;
  description?: string;
  short_description?: string;
  iconPath?: string;
  order?: number;
  coverPath?: string;
  video_path?: string;
  languages?: AppLanguage[];
  partners?: MediaPath[];
  createdAt?: Date | string;
  updatedAt?: Date | string;
  slider?: MediaPath[];

}

export class App implements IApp {
  static readonly clean = Object.freeze(new App());
  id = 0;
  label = '';
  description = '';
  order = 0;
  iconPath = '';
  languages: AppLanguage[] = [];
  partners: MediaPath[] = [];
  delete = false;
  selected = false;
  coverPath = '';
  video_path= '';
  createdAt: Date;
  updatedAt: Date;
  slider: MediaPath[] = [];


  parse(app: IBackendApp) {
    this.id = Number( app.id  || App.clean.id);
    this.label = String(app.label || App.clean.label);
    this.description = String(app.description || App.clean.description);
    this.order = Number(app.order || App.clean.order);
    this.iconPath = String(app.icon_path || App.clean.iconPath);
    this.coverPath = String( app.cover_path || App.clean.coverPath);
    this.video_path = String( app.video_path || App.clean.video_path);

    this.languages = (app.languages || App.clean.languages)
    .map((language: AppLanguage) => new AppLanguage().parse(language));
    this.partners = (app.partners || App.clean.partners).map((partner: MediaPath) => new MediaPath().parse(partner));
    this.slider = (app.slider || App.clean.slider).map((item: MediaPath) => new MediaPath().parse(item));

    const createdAt = app.created_at || App.clean.createdAt;
    this.createdAt = createdAt ? moment.utc(createdAt).toDate() : null;
    const updatedAt = app.updated_at || App.clean.updatedAt;
    this.updatedAt = updatedAt ? moment.utc(updatedAt).toDate() : null;

    return this;
  }

  set(app: IApp) {
    Object.entries(this).forEach(([key]) => {
      this[key] = app[key] || this[key];
    });

    return this;
  }

  clone() {
    return new App().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = App.clean[key];
    });

    return this;
  }
}

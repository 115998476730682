import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatTabsModule} from '@angular/material/tabs';
import { EditorModule } from '@tinymce/tinymce-angular';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { AuthenticationModule } from './_core/modules/authentication/authentication.module';
import { Ng2PanZoomModule, PanZoomConfig } from 'ng2-panzoom';
import {TooltipModule} from 'ng2-tooltip-directive';
import { PipesModule } from './_core/pipes/pipes.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTabsModule,
    EditorModule,
    DragDropModule,
    HttpClientModule,
    AuthenticationModule,
    Ng2PanZoomModule,
    TooltipModule,
    PipesModule
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  private panZoomConfig: PanZoomConfig = new PanZoomConfig;
}

export interface IBackendAppLanguage {
  id?: number;

  label?: string;
  description?: string;
  short_description?: string;
  language?: string;
}

export interface IAppLanguage {
  id?: number;

  label?: string;
  description?: string;
  short_description?: string;  
  language?: string;
}

export class AppLanguage implements IAppLanguage {
  static readonly clean = Object.freeze(new AppLanguage());
  id = 0;

  label = '';
  description = '';
  short_description = '';
  language = '';


  parse(appLanguage: IBackendAppLanguage) {
    this.id = Number(appLanguage.id || AppLanguage.clean.id);

    this.label = String(appLanguage.label || AppLanguage.clean.label);
    this.description = String(appLanguage.description || AppLanguage.clean.description);
    this.short_description = String(appLanguage.short_description || AppLanguage.clean.short_description);
    this.language = String(appLanguage.language || AppLanguage.clean.language);
    return this;
  }

  set(appLanguage: IAppLanguage) {
    Object.entries(this).forEach(([key]) => {
      this[key] = appLanguage[key] || this[key];
    });

    return this;
  }

  clone() {
    return new AppLanguage().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = AppLanguage.clean[key];
    });

    return this;
  }
}

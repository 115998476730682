import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { MediaPath } from '../media-path';
import { Document } from '../document';

export interface IBackendDocumentLanguage {
  id?: number;
  title?: string;
  language?: string;
  description?: string;
  attachment_path?: string;
}

export interface IDocumentLanguage {
  id?: number;
  title?: string;
  language?: string;
  description?: string;
  attachment_path?: string;
}

export class DocumentLanguage implements IDocumentLanguage {
  static readonly clean = Object.freeze(new DocumentLanguage());
  id = 0;
  title = '';
  language = '';
  description = '';
  attachmentPath = ''; 

  parse(documentLanguage: IBackendDocumentLanguage) {
    this.id = Number(documentLanguage.id || DocumentLanguage.clean.id);
    this.attachmentPath = (documentLanguage.attachment_path || DocumentLanguage.clean.attachmentPath);
    this.title = String(documentLanguage.title || DocumentLanguage.clean.title);
    this.language = String(documentLanguage.language || DocumentLanguage.clean.language);
    this.description = String(documentLanguage.description || DocumentLanguage.clean.description);
    return this;
    
  }

  set(documentLanguage: IDocumentLanguage) {
    Object.entries(this).forEach(([key]) => {
      this[key] = documentLanguage[key] || this[key];
    });

    return this;
  }

  clone() {
    return new DocumentLanguage().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = DocumentLanguage.clean[key];
    });

    return this;
  }
}

export interface IBackendMemberLanguage {
  id?: number;
  biography?: string;
  charge?: string;
  department?: string;
  specialization?: string;
  language?: string;
}

export interface IMemberLanguage {
  id?: number;
  biography?: string;
  charge?: string;
  language?: string;
  department?: string;
  specialization?: string;


}

export class MemberLanguage implements IMemberLanguage {
  static readonly clean = Object.freeze(new MemberLanguage());
  id = 0;
  biography = '';
  charge = '';
  language = '';
  department = '';
  specialization = '';

  
  parse(memberLanguage: IBackendMemberLanguage) {
    this.id = Number(memberLanguage.id || MemberLanguage.clean.id);
    this.biography = String(memberLanguage.biography || MemberLanguage.clean.biography);
    this.department = String(memberLanguage.department || MemberLanguage.clean.department);
    this.specialization = String(memberLanguage.specialization || MemberLanguage.clean.specialization);
    this.charge = String(memberLanguage.charge || MemberLanguage.clean.charge);
    this.language = String(memberLanguage.language || MemberLanguage.clean.language);
    return this;
  }

  set(memberLanguage: IMemberLanguage) {
    Object.entries(this).forEach(([key]) => {
      this[key] = memberLanguage[key] || this[key];
    });

    return this;
  }

  clone() {
    return new MemberLanguage().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = MemberLanguage.clean[key];
    });

    return this;
  }
}

import * as moment from 'moment';
import { JobLanguage } from '../job-language';

export interface IBackendJob {
  id?: number;
  title?: string;
  language?: string;
  description?: string;
  languages?: JobLanguage[];
}

export interface IJOb {
  id?: number;  
  languages?: JobLanguage[];
}

export class Job implements IJOb {
  static readonly clean = Object.freeze(new Job());
  id = 0;
  title = '';
  languages: JobLanguage[] = [];

  parse(job: IBackendJob) {
    this.id = Number(job.id || Job.clean.id);
    this.title = String(job.title || Job.clean.title);
    this.languages = (job.languages || Job.clean.languages).map((language: JobLanguage) => new JobLanguage().parse(language));
    

    return this;
  }

  set(job: IJOb) {
    Object.entries(this).forEach(([key]) => {
      this[key] = job[key] || this[key];
    });

    return this;
  }

  clone() {
    return new Job().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = Job.clean[key];
    });

    return this;
  }
}

import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { MediaPath } from '../media-path';
import { Initiative } from '../initiative';

export interface IBackendLanguage {
  id?: number;
  label?: string;
  population?: string;
  description?: string;
  country_name?: string;
  language?: string;
  continent?: string;
}

export interface ILanguage {
  id?: number;
  label?: string;
  population?: string;
  description?: string;
  countryName?: string;
  language?: string;
  continent?: string;
}

export class Language implements ILanguage {
  static readonly clean = Object.freeze(new Language());
  id = 0;
  label = '';
  description = '';
  countryName = '';
  language = '';
  continent = '';
  population = '';


  parse(language: IBackendLanguage) {
    this.id = Number(language.id || Language.clean.id);

    this.label = String(language.label || Language.clean.label);
    this.population = String(language.population || Language.clean.population);
    this.description = String(language.description || Language.clean.description);
    this.countryName = String(language.country_name || Language.clean.countryName);
    this.language = String(language.language || Language.clean.language);
    this.continent = String(language.continent || Language.clean.continent);

    return this;
  }

  set(language: ILanguage) {
    Object.entries(this).forEach(([key]) => {
      this[key] = language[key] || this[key];
    });

    return this;
  }

  clone() {
    return new Language().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = Language.clean[key];
    });

    return this;
  }
}

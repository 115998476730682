import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipesRoutingModule } from './pipes-routing.module';
import { SearchFilterPipe } from './search.pipe';

const moduleExports = [ SearchFilterPipe ]

@NgModule({
  declarations: [moduleExports],
  imports: [
    CommonModule,
    PipesRoutingModule
  ],
  exports: [
    moduleExports
  ]
})
export class PipesModule { }

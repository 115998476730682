import * as moment from 'moment';
import { InitiativeLanguage } from '../initiative-language';
import { MediaPath } from '../media-path';

export interface IBackendInitiative {
  id?: number;

  location_id?: number;
  country_name?: string;
  title?: string;
  body?: string;
  work_area?: number;
  video_path?: string;

  cover_path?: string;
  short_description?: string;
  languages?: InitiativeLanguage[];
  slider?: MediaPath[];
  poblacion?: string;
  

  created_at?: string;
  updated_at?: string;
}

export interface IInitiative {
  id?: number;

  locationId?: number;
  locationName?: string;
  title?: string;
  body?: string;
  work_area?: number;
  poblacion?: string;
video_path?: string;
  coverPath?: string;
  shortDescription?: string;
  languages?: InitiativeLanguage[];
  slider?: MediaPath[];
  createdAt?: Date | string;
  updatedAt?: Date | string;
}

export class Initiative implements IInitiative {
  static readonly clean = Object.freeze(new Initiative());
  id = 0;

  locationId = 0;
  locationName = '';
  delete = false;
  selected = false;
  title = '';
  body = '';
  work_area= 0;

  poblacion= '';
  coverPath = '';

  video_path = '';
  shortDescription = '';
  languages: InitiativeLanguage[] = [];
  slider: MediaPath[] = [];

  createdAt: Date;
  updatedAt: Date;


  parse(initiative: IBackendInitiative) {
    this.id = Number(initiative.id || Initiative.clean.id);

    this.locationId = Number(initiative.location_id || Initiative.clean.locationId);
    this.locationName = String(initiative.country_name || Initiative.clean.locationName);
    this.title = String(initiative.title || Initiative.clean.title);
    this.body = String(initiative.body || Initiative.clean.body);
    this.work_area = Number(initiative.work_area || Initiative.clean.work_area);
    this.poblacion = String(initiative.poblacion || Initiative.clean.poblacion);

    this.video_path = String(initiative.video_path || Initiative.clean.video_path);



    this.coverPath = String(initiative.cover_path || Initiative.clean.coverPath);
    this.shortDescription = String(initiative.short_description || Initiative.clean.shortDescription);
    this.languages = (initiative.languages || Initiative.clean.languages)
    .map((language: InitiativeLanguage) => new InitiativeLanguage().parse(language));
    this.slider = (initiative.slider || Initiative.clean.slider).map((item: MediaPath) => new MediaPath().parse(item));


    const createdAt = initiative.created_at || Initiative.clean.createdAt;
    this.createdAt = createdAt ? moment.utc(createdAt).toDate() : null;
    const updatedAt = initiative.updated_at || Initiative.clean.updatedAt;
    this.updatedAt = updatedAt ? moment.utc(updatedAt).toDate() : null;

    return this;
  }

  set(initiative: IInitiative) {
    Object.entries(this).forEach(([key]) => {
      this[key] = initiative[key] || this[key];
    });

    return this;
  }

  clone() {
    return new Initiative().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = Initiative.clean[key];
    });

    return this;
  }
}
